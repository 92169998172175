import { useEffect, useMemo, useState } from 'react';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HomeFilter, useGeoContext } from '@buydepa-public/ui';
import Hero from '@/components/home/Hero';
import Layout from '@/components/templates/Layout';
import OurPartners from '@/components/home/OurPartners';
import { motion } from 'framer-motion';
import Reasons from '@/components/home/Reasons';
import Services from '@/components/home/Services';
import Button from '@/components/scarlett-ui-components/Button';
import config from '@/utils/seo.json';
import { Property } from '../../../../libs/ui/src/lib/types/property';
import { FormikValues } from 'formik';
import { GetStaticProps } from 'next';
import SvgAnimatedMob from '@/components/home/SvgAnimatedMob';
import SvgAnimatedDesk from '@/components/home/SvgAnimatedDesk';
import useWindowSize from '@/hooks/useWindowSize';
import ModalDepaMatch from '@/components/product/ModalDepaMatch';
import PropertiesCarousel from '@/components/home/PropertiesCarousel';
import NewsCarousel from '@/components/home/NewsCarousel';

export const getStaticProps: GetStaticProps = async (context) => {
  // const { locale } = context //TODO, i18n Locale para dominios o paths folder
  try {
    const country = await fetch(
      `${process.env.WEBSITE_APP_URL}/api/get-country`,
    ).then((res) => res.json());
    const filters = await fetch(
      `${process.env.WEBSITE_APP_URL}/api/get-filter-limits?country=${country}`,
    ).then((res) => res.json());
    const properties = await fetch(
      `${process.env.WEBSITE_APP_URL}/api/stock-properties-filter`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filters: {
            ...filters,
            country: country,
            new: false,
            semi: true,
          },
          start: 0,
          limit: 100,
        }),
      },
    ).then((res) => res.json());
    return {
      revalidate: 60 * 60 * 12,
      props: {
        filters: filters,
        properties: properties?.data || null,
      },
    };
  } catch (error) {
    console.log(
      `%c🔔 URL: ${process.env.WEBSITE_APP_URL}/api/get-filter-limits?country=CL`,
      'color: red; font-size: 16px; font-weight: bold; background: #000; padding: 5px; border-radius: 5px;',
    );
  }
  return {
    revalidate: 60 * 60 * 12,
    props: {
      properties: [],
    },
  };
};

interface HomeProps {
  properties: Property[];
  filters: any;
}
export default function Home({ properties, filters }: HomeProps) {
  const [width] = useWindowSize();
  const { pathname, push, query } = useRouter();
  const [openDepaMatch, setOpenDepaMatch] = useState(false);
  useEffect(() => {
    if (query?.depamatch === '1') {
      setOpenDepaMatch(true);
    }
  }, [query]);
  const { currentConfig } = useGeoContext();

  useEffect(() => {
    if (pathname !== currentConfig.href) {
      push(currentConfig.href);
    }
  }, [currentConfig]);

  const communes = useMemo(() => filters?.commune || [], [filters]);

  const bedrooms = useMemo(() => {
    const options = [];
    const max = 5;
    for (let i = 0; i < max; i++) {
      options.push((i + 1).toString());
    }
    return options;
  }, [filters]);

  const bathrooms = useMemo(() => {
    const options = [];
    const max = 5;
    for (let i = 0; i < max; i++) {
      options.push((i + 1).toString());
    }
    return options;
  }, [filters]);

  if (!width) return null;

  const isMobile = width < 768;

  const handleFiltersSubmit = (values: FormikValues) => {
    const urlParams = new URLSearchParams();
    if (values.commune && values.commune.length > 0)
      urlParams.append('commune', values.commune.join(','));
    if (values.bathrooms) urlParams.append('bathrooms', values.bathrooms);
    if (values.bedrooms) urlParams.append('bedrooms', values.bedrooms);
    if (values.view) urlParams.append('view', values.view);
    const query = urlParams.toString();
    push(`/compra?${query}`);
  };

  return (
    <>
      <NextSeo
        {...config}
        openGraph={{
          type: 'website',
          url: 'https://buydepa.com/',
          title: 'Buydepa - Accede a tu vivienda!',
          description:
            'Vende sin pagar comisiones y evita todos los trámites asociados a tu venta',
          images: [
            {
              url: '/images/default-seo.jpg',
              width: 1200,
              height: 630,
              alt: 'Buydepa',
            },
          ],
        }}
      />
      {/* {noticeBanner[0] && noticeBanner[0].activated && <Banner text={noticeBanner[0].text} link={noticeBanner[0].link} />} */}
      <Layout>
        <section className="relative z-10">
          <Hero />
        </section>
        <div className="relative">
          <div className="relative z-10 pt-8 md:pt-0">
            <HomeFilter
              communes={communes as string[]}
              bedrooms={bedrooms}
              bathrooms={bathrooms}
              onSubmit={handleFiltersSubmit}
            />
            <div className="text-center pl-10 md:pl-0">
              <p className="text-secondary text-lg font-bold">
                <a
                  href="#"
                  onClick={() => setOpenDepaMatch(true)}
                  className="inline-flex cursor-pointer rounded-md text-primary hover:text-primary-300 hover:underline p-2 mb-4"
                >
                  ¿No encuentras lo que buscas?
                </a>
              </p>
            </div>
            <div className="overflow-hidden">
              <div className="bg-[url('../../public/images/home-background-elipse-1.png')] bg-right bg-contain bg-no-repeat">
                <div className="flex flex-col items-center justify-center mx-auto max-w-7xl ">
                  <h3 className="pl-10 md:pl-0 text-center text-buydepa-primary text-3xl font-normal text-primary">
                    Buydepa en los medios
                  </h3>

                  <NewsCarousel />

                  <h3 className="pl-10 md:pl-0 text-center text-buydepa-primary text-3xl font-normal text-primary">
                    Descubre las oportunidades de hoy
                  </h3>

                  <PropertiesCarousel
                    title="Some Title"
                    properties={properties}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 mt-8 xl:grid-cols-1 gap-6"></div>
                  <div id="reasons">
                    <Reasons />
                  </div>
                  <Link href={'/compra'}>
                    <Button
                      Component={'div'}
                      color="primary"
                      variant="fill"
                      className="my-12 rounded-md bg-primary-400"
                    >
                      {' '}
                      Ver más propiedades{' '}
                    </Button>
                  </Link>
                  <div className="pl-14 md:pl-0 text-center mt-14 mx-4 block sm:hidden mb-24">
                    <span className="text-slate-900 text-2xl font-normal">
                      Te ayudamos a acceder a una vivienda digna a un{' '}
                    </span>
                    <span className="text-slate-900 text-2xl font-semibold">
                      menor costo, en la mejor ubicación posible
                    </span>
                    <span className="text-slate-900 text-2xl font-normal font-['Noto Sans']">
                      {' '}
                      y con{' '}
                    </span>
                    <span className="text-slate-900 text-2xl font-semibold font-['Noto Sans']">
                      entrega inmediata.
                    </span>
                  </div>
                </div>
              </div>
              <motion.div
                initial={isMobile ? 'visible' : 'hidden'}
                whileInView="visible"
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      type: 'spring',
                      delay: 0.5,
                      duration: 1.5,
                    },
                  },
                  hidden: {
                    opacity: 0,
                    x: 100,
                  },
                }}
                viewport={{ once: true, amount: 0.5 }}
              ></motion.div>
              <motion.div
                initial={isMobile ? 'visible' : 'hidden'}
                whileInView="visible"
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      type: 'spring',
                      delay: 0.5,
                      duration: 1.5,
                    },
                  },
                  hidden: {
                    opacity: 0,
                    x: 100,
                  },
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <div className="bg-[url('../../public/images/home-background-elipse-2.png')] bg-left-top bg-contain bg-no-repeat">
                  <div className="pt-16 sm:pt-24">
                    <Services />
                  </div>
                </div>
              </motion.div>
              {/* <CustomerTestimonials /> */}
              <motion.div
                initial={isMobile ? 'visible' : 'hidden'}
                whileInView="visible"
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      type: 'spring',
                      delay: 0.5,
                      duration: 1.5,
                    },
                  },
                  hidden: {
                    opacity: 0,
                    x: 100,
                  },
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <section className="mb-20 mt-20">
                  <OurPartners />
                </section>
              </motion.div>
            </div>
          </div>
          <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
            <SvgAnimatedMob className="md:hidden mx-auto" />
            <SvgAnimatedDesk className="hidden md:block mx-auto" />
          </div>
        </div>
        <ModalDepaMatch open={openDepaMatch} setOpen={setOpenDepaMatch} />
      </Layout>
    </>
  );
}
