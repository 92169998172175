import React from 'react';
import CustomCarousel from '../scarlett-ui-components/CustomCarousel';
import CardWebsite from '../../../../../libs/ui/src/lib/components/CardWebsite/CardWebsite';

export interface PropertiesCarouselProps {
  title?: string;
  properties: any;
}

export default function PropertiesCarousel({
  title,
  properties,
}: PropertiesCarouselProps) {
  // Map properties to CardWebsite components
  const carouselItems = properties?.map((property: any) => (
    <CardWebsite key={property.id} property={property} />
  )) || [];

  return (
    <>
      <section className="">
        <div className="xl:hidden flex items-center justify-center">
          <CustomCarousel items={carouselItems} showArrows itemsPerSlide={1} />
        </div>
        <div className="mx-auto lg:max-w-7xl hidden xl:flex items-center justify-center">
          <CustomCarousel items={carouselItems} showArrows itemsPerSlide={3} />
        </div>
      </section>
    </>
  );
}
