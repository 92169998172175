import Image from 'next/image';
import Link from 'next/link';
import Button from '../scarlett-ui-components/Button';

export default function Hero() {
  return (
    <div className="relative pt-3 py-28 lg:py-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden bg-[url('/images/home/hero.png')] bg-cover bg-center  w-full">
      <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-black opacity-100 z-10"></div>
      <div className="mx-auto max-w-7xl lg:px-8 relative z-20">
        <div className="lg:grid grid-cols-3  md:grid-cols-2 ">
          <div className="max-w-md px-12 text-center md:text-left  lg:px-4 mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:pb-24">
              <h1 className="mt-6 text-2xl  sm:mt-5 lg:mt-6  md:text-3xl xl:text-4xl  text-txLight">
                <span className="block font-medium sm:font-bold">
                  Conviértete en dueño de tu primer hogar
                </span>
              </h1>
              <p className="text-txLight font-normal text-sm sm:text-xl lg:text-xl xl:text-2xl my-2 md:my-4">
                Compra tu depa con financiamiento a medida
              </p>
              <div className=" p-4 mt-4 lg:mt-0 lg:pt-0 lg:pl-0 text-center sm:text-center md:text-left">
                <Link href="#reasons">
                  <Button
                    color="secondary"
                    className="inline-flex cursor-pointer rounded-md bg-secondary hover:bg-secondary"
                  >
                    Conocer más
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
