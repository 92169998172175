import React, { useState } from 'react';
import { LeftArrow, RightArrow } from '@buydepa-public/ui';

interface ICarouselProps {
  items: JSX.Element[];
  showDots?: boolean;
  showArrows?: boolean;
  itemsPerSlide?: number;
}

export default function CustomCarousel({
  items,
  showDots,
  showArrows,
  itemsPerSlide = 1,
}: ICarouselProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [startX, setStartX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startX === null) return;

    const currentX = e.touches[0].clientX;
    const diff = startX - currentX;

    const swipeThreshold = 50;

    if (diff > swipeThreshold) {
      goToNextSlide();
      setStartX(null);
    } else if (diff < -swipeThreshold) {
      goToPrevSlide();
      setStartX(null);
    }
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1,
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  const duplicatedItems = [...items, ...items, ...items];

  return (
    <div
      className="relative overflow-hidden"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div
        className="flex transition-all duration-150"
        style={{
          width: `${(duplicatedItems.length / itemsPerSlide) * 100}%`,
          transform: `translateX(-${
            currentIndex * (100 / duplicatedItems.length)
          }%)`,
          transition: `transform 150ms ease-in-out`,
        }}
      >
        {duplicatedItems.map((item, index) => (
          <div key={index} className="mx-auto py-20 flex-shrink-0">
            <div>{item}</div>
          </div>
        ))}
      </div>
      {showDots && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex xl:hidden space-x-3">
          {items.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? 'bg-buydepa-blue' : 'bg-ghost-blue-600'
              }`}
              onClick={() => goToSlide(index)}
            ></button>
          ))}
        </div>
      )}
      {showArrows && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 hidden xl:flex space-x-6">
          <button
            className="rounded-full bg-violet-100 p-2"
            onClick={goToPrevSlide}
          >
            <LeftArrow />
          </button>

          <button
            className="rounded-full bg-violet-100 p-2"
            onClick={goToNextSlide}
          >
            <RightArrow />
          </button>
        </div>
      )}
    </div>
  );
}
