import React from 'react';
import CustomCarousel from '../scarlett-ui-components/CustomCarousel';
import NewsCard from './NewsCard';

export default function NewsCarousel() {
  const news = [
    {
      image: {
        url: '/images/home/news/cnn-chile.png',
        alt: 'CNN Chile',
      },
      url: 'https://www.cnnchile.com/programas-completos/agenda-economica-nicolas-mendez-acceso-vivienda-plataformas_20230317/',
    },
    {
      image: {
        url: '/images/home/news/diario-financiero.png',
        alt: 'Diario Financiero',
      },
      url: 'https://www.df.cl/df-lab/innovacion-y-startups/buydepa-la-startup-chilena-que-busca-consolidarse-en-el-mercado-de-las',
    },
    {
      image: {
        url: '/images/home/news/duna.png',
        alt: 'Duna',
      },
      url: 'https://www.duna.cl/podcasts/ip-pm-empleados-por-empresas-y-el-momento-de-buydepa/',
    },
    {
      image: {
        url: '/images/home/news/forbes.png',
        alt: 'Forbes',
      },
      url: 'https://forbes.cl/negocios/2022-08-17/reducir-a-10-dias-el-proceso-de-venta-de-una-propiedad-esa-es-la-hoja-de-ruta-de-la-startup-chilena-buydepa',
    },
    {
      image: {
        url: '/images/home/news/la-hora.png',
        alt: 'La Hora',
      },
      url: 'https://lahora.cl/cronica/2023/03/07/familias-beneficiarias-de-subsidio-habitacional-tendran-opciones-de-vivienda-mas-centrica-en-santiago/',
    },
    {
      image: {
        url: '/images/home/news/la-tercera.png',
        alt: 'La Tercera',
      },
      url: 'https://www.latercera.com/pulso/noticia/buydepa-y-la-era-de-los-instant-buyers/H4WVLAF5SFB2PIFYIJM33MIXWI/',
    },
  ];

  const carouselItems = news.map((property: any) => (
    <NewsCard property={property} />
  ));

  return (
    <>
      <section className="">
        <div className="xl:hidden flex items-center justify-center">
          <CustomCarousel items={carouselItems} showDots itemsPerSlide={1} />
        </div>
        <div className="mx-auto lg:max-w-7xl hidden xl:flex items-center justify-center">
          <CustomCarousel items={carouselItems} showArrows itemsPerSlide={3} />
        </div>
      </section>
    </>
  );
}
