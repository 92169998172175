import { LogoBancoEstado, LogobtgPactual, LogoHmc } from '@buydepa-public/ui';
export default function OurPartners() {
  const ourPartners = {
    partners: [
      { name: 'Btg pactual', logo: <LogobtgPactual /> },
      { name: 'Banco Estado', logo: <LogoBancoEstado /> },
      { name: 'HMC', logo: <LogoHmc /> },
    ],
  };
  return (
    <section className="m-8">
      <h3 className="text-center text-primary text-2xl md:text-3xl font-normal">
        Conoce a nuestros partners
      </h3>
      <div className=" m-12">
        <ul className="flex flex-col lg:flex-row items-center justify-center">
          {ourPartners.partners.map((item) => (
            <li key={item.name} className="m-8">
              <div>{item.logo}</div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
