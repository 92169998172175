import {
  HandCoin,
  WrenchAndScrewDriver,
  Stickhouse,
  ThumbsUp,
} from '@buydepa-public/ui';
import CustomCarousel from '../scarlett-ui-components/CustomCarousel';

function Financing() {
  return (
    <div className="bg-primary w-[330px] relative h-96 bg-black/20 flex items-center flex-col space-y-8 p-16 border border-slate-400 border-opacity-40 rounded-xl">
      <div className="absolute z-20 p-7 -top-14 rounded-tl-3xl rounded-br-3xl rounded-tr-sm rounded-bl-sm bg-secondary">
        <HandCoin color="#2F0CF0" />
      </div>
      <p className="font-semibold uppercase text-lg text-opacity-90 text-txLight">
        Te damos opciones de financiamiento
      </p>
      <div className="flex flex-col space-y-6 items-start justify-center">
        <div className="flex items-center justify-center space-x-4">
          <p className="font-semibold text-txLight">
            Te conseguimos financiamiento con los bancos y te financiamos hasta
            el 15% de valor del depa a tasa cero!
          </p>
        </div>
      </div>
    </div>
  );
}

function Renovations() {
  return (
    <div className="bg-primary w-[330px] relative h-96 bg-black/20 flex items-center flex-col space-y-8 p-16 border border-slate-400 border-opacity-40 rounded-xl">
      <div className="absolute z-20 p-6 -top-14 rounded-tl-3xl rounded-br-3xl rounded-tr-sm rounded-bl-sm bg-secondary">
        <WrenchAndScrewDriver color="#2F0CF0" />
      </div>
      <p className="font-semibold uppercase text-lg text-opacity-90 text-txLight">
        Renovamos todos nuestros departamentos
      </p>
      <div className="flex flex-col space-y-6 items-start justify-center">
        <div className="flex items-center justify-center space-x-4">
          <p className="font-semibold text-txLight">
            Tenemos contratistas expertos que dejan los departamentos en estado
            de seminuevos
          </p>
        </div>
      </div>
    </div>
  );
}

function Warranty() {
  return (
    <div className="bg-primary w-[330px] relative h-96 bg-black/20 flex items-center flex-col space-y-8 p-16 border border-slate-400 border-opacity-40 rounded-xl">
      <div className="absolute z-20 p-6 -top-14 rounded-tl-3xl rounded-br-3xl rounded-tr-sm rounded-bl-sm bg-secondary">
        <Stickhouse color="#2F0CF0" />
      </div>
      <p className="font-semibold uppercase text-lg text-opacity-90 text-txLight">
        Te damos 6 meses de garantía de postventa
      </p>
      <div className="flex flex-col space-y-6 items-start justify-center">
        <div className="flex items-center justify-center space-x-4">
          <p className="font-semibold text-txLight">
            Cobertura para todos los problemas funcionales del departamentos
          </p>
        </div>
      </div>
    </div>
  );
}

function Assistance() {
  return (
    <div className="bg-primary w-[330px] relative h-96 bg-black/20 flex items-center flex-col space-y-8 p-16 border border-slate-400 border-opacity-40 rounded-xl">
      <div className="absolute z-20 p-6 -top-14 rounded-tl-3xl rounded-br-3xl rounded-tr-sm rounded-bl-sm bg-secondary">
        <ThumbsUp color="#2F0CF0" />
      </div>
      <p className="font-semibold uppercase text-lg text-opacity-90 text-txLight">
        Asesoría personalizada
      </p>
      <div className="flex flex-col space-y-6 items-start justify-center">
        <div className="flex items-center justify-center space-x-4">
          <p className="font-semibold text-txLight">
            Te acompañaremos durante todo el proceso, haremos el seguimiento por
            ti, para que sea una experiencia fácil y segura
          </p>
        </div>
      </div>
    </div>
  );
}

const cards: JSX.Element[] = [
  <Financing />,
  <Renovations />,
  <Warranty />,
  <Assistance />,
];

export default function Reasons() {
  return (
    <>
      <section>
        <h3 className="hidden md:block py-24 text-center text-primary  text-2xl md:text-3xl font-normal">
          Razones para elegirnos
        </h3>

        <section className="hidden py-20 px-16 lg:flex items-center justify-between space-x-6">
          {cards.map((card, idx) => (
            <div key={idx} className="flex-1 px-4">
              {card}
            </div>
          ))}
        </section>
        <section className="lg:hidden flex items-center justify-center">
          <CustomCarousel items={cards} showDots itemsPerSlide={1} />
        </section>
      </section>
    </>
  );
}
