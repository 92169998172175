const SvgAnimatedMob = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={325}
      height={4796}
      viewBox="0 0 325 4796"
      fill="white"
      {...props}
    >
      <path
        strokeWidth={1}
        stroke="#19EC93"
        strokeOpacity={0.85}
        d="M144.224 0v246.698c0 22.092-17.908 40-40 40H41.019c-22.092 0-40 17.909-40 40v97.934c0 22.091 17.908 40 40 40h88.653c17.163 0 31.076 13.913 31.076 31.076V2302.38c0 22.09-17.908 40-40 40H49.03c-22.091 0-40 17.91-40 40V2558c0 22.09 17.909 40 40 40h150.331c22.092 0 40 17.91 40 40v545.18c0 7.12 1.901 14.11 5.508 20.25l75.018 127.75a30.6 30.6 0 0 1 2.596 5.69c6.641 19.72-8.023 40.13-28.824 40.13H48c-22.091 0-40 17.91-40 40v1378.05"
      />
    </svg>

  )
}
export default SvgAnimatedMob
