import Image from 'next/image';

interface ICardNewsProps {
  property: any;
}

export default function NewsCard({ property }: ICardNewsProps) {
  return (
    <div className="flex items-center h-[120px] w-max hover:border-b-2 hover:border-b-primary">
      <div className="flex">
        <a href={property.url} target="_blank" rel="noopener noreferrer">
          <Image
            loading="lazy"
            width={100}
            height={40}
            unoptimized
            src={property.image.url}
            alt={property.image.alt}
          />
        </a>
      </div>
    </div>
  );
}
