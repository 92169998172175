import { classNames } from '@/utils/class-names';
import {
  ButtonHTMLAttributes,
  FC,
  forwardRef,
  JSXElementConstructor,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import Spinner from '../atoms/Spinner';

export type BDColors =
  | 'blue'
  | 'magenta'
  | 'primary'
  | 'secondary'
  | 'lightGray'
  | 'ghostBlue'
  | 'black'
  | 'alert'
  | 'success'
  | 'warning';
type Variant = 'border' | 'fill' | 'link';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  className?: string;
  variant?: Variant;
  color?: BDColors;
  active?: boolean;
  type?: 'submit' | 'reset' | 'button';
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = forwardRef((props: ButtonProps, buttonRef) => {
  const {
    className,
    variant = 'border',
    children,
    active,
    width,
    color = 'blue',
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    ...rest
  } = props;

  const ref = useRef<typeof Component>(null);
  const colorsBorder: { [P in BDColors]: string } = {
    blue: 'border-blue-600 text-blue-600 transition-all hover:bg-blue-600 hover:text-white',
    magenta:
      'border-magenta-600 text-magenta-600 transition-all hover:bg-magenta-600 hover:text-white',
    primary:
      'border-primary-600 text-primary-600 transition-all hover:bg-primary-600 hover:text-white',
    secondary:
      'border-secondary text-primary-600 transition-all hover:bg-primary-600 hover:text-primary-300',
    lightGray: 'border-light-gray-600 text-light-gray-600',
    ghostBlue: 'border-ghost-blue-600 text-ghost-blue-600',
    black: 'border-black-100 text-black-100',
    alert: '',
    success: '',
    warning: '',
  };
  const colorsFill: { [P in BDColors]: string } = {
    blue: 'bg-blue-600 text-white transition-all hover:bg-blue-500',
    magenta: 'bg-magenta-600 text-white transition-all hover:bg-magenta-500',
    primary: 'bg-primary-600 text-white transition-all hover:bg-primary-500',
    secondary: 'bg-primary-600 text-white transition-all hover:bg-primary-500',
    lightGray:
      'bg-light-gray-600 text-primary-600 transition-all hover:bg-light-gray-500',
    ghostBlue:
      'bg-ghost-blue-600 text-primary-600 transition-all hover:bg-ghost-blue-500',
    black: 'bg-black-100 text-white transition-all hover:bg-black-200',
    alert: '',
    success: '',
    warning: '',
  };
  const classVariant: { [P in Variant]: string } = {
    border: colorsBorder[color],
    fill: colorsFill[color],
    link: '',
  };
  const rootClassName = classNames(
    'py-3 px-8 font-bold rounded-full text-sm inline-flex items-center',
    classVariant[variant],
    variant === 'fill' && 'shadow-md',
    variant === 'border' && 'border-[3px]',
    disabled &&
      variant === 'border' &&
      '!border-gray-300 !text-gray-300 hover:!bg-inherit',
    disabled && variant === 'fill' && '!bg-gray-300 !text-white',
    className,
  );

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? 'Cargando ' : children}
      {loading && (
        <i className="ml-2">
          <Spinner />
        </i>
      )}
    </Component>
  );
});

Button.displayName = 'Button';

export default Button;
