import { useRouter } from 'next/router';
import React from 'react';
import { useState } from 'react';
import Link from 'next/link';
import Main from './Main';
import BreadCrumb from '@/components/molecules/BreadCrumb';
import { FooterGeneral, HeaderGeneral, getIcon } from '@buydepa-public/ui';

type Props = {
  children?: React.ReactNode;
  breadCrumb?: {
    name: string;
    href: string;
    current: boolean;
  }[];
};

const socialMediaLinks = [
  {
    path: '/',
    social: [
      {
        name: 'Whatsapp',
        description: 'Comunícate por whatsapp',
        target: '_blank',
        href: 'https://api.whatsapp.com/send?phone=+56962253968&text=Hola, me gustaría recibir más información',
        icon: (props: any) => getIcon('Whatsapp', { ...props, color: '#fff' }),
      },
    ],
  },
];

const Layout: React.FC<Props> = ({ children, breadCrumb }) => {
  const { pathname } = useRouter();
  const [showDescription, setShowDescription] = useState(false);

  const socialLinks = socialMediaLinks.find(
    (item) => item.path === '/',
  )?.social;

  const handleMouseEnter = (item: any) => {
    setShowDescription(true);
  };

  return (
    <div className="bg-white mx-auto">
      <div className="fixed w-full z-40">
        <div className="container mx-auto relative">
          {!(
            pathname === '/co' ||
            pathname === '/mx' ||
            pathname === '/tracking/[sku]'
          ) && (
            <div className="absolute right-5 2xl:right-32 top-[30rem] md:top-[29rem] lg:top-[32rem] print:hidden">
              <div className="bg-[#289303] rounded-full drop-shadow">
                {socialLinks?.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    target={item.target}
                    rel="noreferrer"
                  >
                    <div
                      className="w-14 h-14 rounded-full flex items-center justify-center relative group hover:bg-[#207D00] cursor-pointer"
                      onMouseEnter={() => handleMouseEnter(item)}
                      onMouseLeave={() => setShowDescription(false)}
                    >
                      {showDescription && (
                        <div className="absolute bg-[#289303] group-hover:bg-[#207D00] text-xs right-4 text-white rounded-2xl py-2 px-3 pr-10 whitespace-nowrap opacity-0 group-hover:opacity-100 transition-all">
                          {item.description}
                        </div>
                      )}
                      <div className="ml-1 flex items-center justify-center rounded-full relative z-10 group-hover:bg-[#207D00]">
                        <item.icon aria-hidden="true" />
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="relative">
        <HeaderGeneral />
        <Main>
          {breadCrumb && <BreadCrumb pages={breadCrumb} />}
          {children}
        </Main>
        <FooterGeneral />
      </div>
    </div>
  );
};

export default Layout;
