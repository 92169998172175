import { useEffect, useRef, useState } from "react";

function updatePath(
  path: SVGPathElement | null,
  scrollPercentage: number,
  drawStart: number,
  drawEnd: number
) {
  if (!path) {
    return;
  }

  const pathLength = path.getTotalLength();
  path.style.strokeDasharray = `${pathLength} ${pathLength}`;
  path.style.strokeDashoffset = pathLength.toString();

  const drawLength = pathLength * scrollPercentage;
  path.style.strokeDashoffset = String(pathLength - drawLength);
}
function updatePath2(
  path: SVGPathElement | null,
  scrollPercentage: number
) {
  if (!path) {
    return;
  }
  const path2Length = path.getTotalLength();
  path.style.strokeDasharray = `${path2Length} ${path2Length}`;
  path.style.strokeDashoffset = path2Length.toString();

  let drawLength2 = 0;

  if (scrollPercentage >= 0.2 && scrollPercentage <= 0.3) {
    drawLength2 = path2Length * (scrollPercentage - 0.2) / 0.1;
  } else if (scrollPercentage > 0.3) {
    drawLength2 = path2Length;
  }

  path.style.strokeDashoffset = `-${path2Length - drawLength2}`;
}
function updatePath3Opacity(
  path: SVGPathElement | null, scrollPercentage: number
) {
  if (!path) {
    return;
  }

  let opacity = 0;
  if (scrollPercentage >= 0.2 && scrollPercentage <= 0.3) {
    opacity = (scrollPercentage - 0.2) / 0.1;
  } else if (scrollPercentage > 0.3) {
    opacity = 1;
  }

  path.style.opacity = opacity.toString();
}
const SvgAnimatedDesk = (props: React.SVGProps<SVGSVGElement>) => {
  const path1Ref = useRef<SVGPathElement | null>(null);
  const path2Ref = useRef<SVGPathElement | null>(null);
  const path3Ref = useRef<SVGPathElement | null>(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    function handleScroll() {
      const currentScrollPercentage =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      setScrollPercentage(currentScrollPercentage);
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    updatePath(path1Ref.current, scrollPercentage, 0, 0);
    updatePath2(path2Ref.current, scrollPercentage);
    updatePath3Opacity(path3Ref.current, scrollPercentage);
  }, [scrollPercentage]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1233}
      height={3489}
      viewBox="0 0 1233 3489"
      fill="white"
      {...props}
    >
      <path
        ref={path3Ref}
        fill="#19EC93"
        d="M136 1090.5h13v13h-13zM173 1090.5h13v13h-13zM136 1166.5h13v13h-13zM136 1207.5h13v13h-13zM136 1128.5h13v13h-13zM197 1150.5h10v10h-10zM182 1181.5h10v10h-10zM197 1209.5h10v10h-10z"
      />
      <path
        ref={path1Ref}
        style={{strokeDashoffset: "7475", strokeDasharray: "7475 7475"}}
        stroke="#19EC93"
        d="M941.5.5V147c0 44.183-35.817 80-80 80h-183c-44.183 0-80 35.817-80 80v600.5c0 44.183-35.817 80-80 80H56c-30.376 0-55 24.62-55 55V1152c0 45.84 37.16 83 83 83h31v-148.5c0-10.49 8.507-19 19-19h51c10.493 0 19 8.51 19 19v51h4c5.523 0 10 4.48 10 10v63.5c0 13.25 10.745 24 24 24h908.5c44.18 0 80 35.82 80 80v357.91c0 32.79-23.4 60.91-55.65 66.87L173.442 1924.69a61.822 61.822 0 0 0-31.209 15.83l-1.977 1.87c-24.699 23.32-22.464 63.25 4.683 83.67a39.586 39.586 0 0 0 23.775 7.94H1116.5c39.76 0 72 32.24 72 72v523c0 42.53-34.47 77-77 77H700c-39.765 0-72 32.24-72 72v259"
      />
      <path ref={path2Ref} stroke="#19EC93" d="M164 1237v-89.5c0-5.52 4.477-10 10-10h30" fill="none" />
    </svg>
  )
}
export default SvgAnimatedDesk
