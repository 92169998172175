import { useState } from 'react';
import Image from 'next/image';

type Feature = {
  name: string;
  description: string;
  src: string;
};

type Features = {
  buy: Feature[];
  sell: Feature[];
};

export const BuyAndSellFeatures: Features = {
  buy: [
    {
      name: 'Revisa nuestras ofertas',
      description:
        'Puedes revisar nuestro stock de departamentos seminuevos en las mejores ubicaciones de Santiago por precios más accesibles que el promedio del mercado.',
      src: '/images/home/revisa_nuestras_ofertas.png',
    },
    {
      name: 'Agenda una visita',
      description:
        'Un experto inmobiliario te acompañará durante todo el proceso y te ayudará a despejar todas las dudas que tengas sobre el depa.',
      src: '/images/home/agenda_una_visita.png',
    },
    {
      name: 'Compra la propiedad',
      description:
        'Te damos facilidades de pago y ayudamos con la gestión del financiamiento, trámite legal y entrega de tu próximo hogar o inversión.',
      src: '/images/home/compra_la_propiedad.png',
    },
  ],
  sell: [
    {
      name: 'Cotiza tu propiedad',
      description:
        'Evaluamos tu propiedad al instante. Nuestro software analiza todas las variables asociadas al departamento y genera una oferta inmediata para ti.',
      src: '/images/home/cotiza_tu_propiedad.png',
    },
    {
      name: 'Coordinamos una visita',
      description:
        'Si estás de acuerdo con la oferta, coordinamos una visita de verificación. Con eso resuelto, te enviamos una oferta final.',
      src: '/images/home/coordinamos_visita.png',
    },
    {
      name: 'Compramos tu propiedad',
      description:
        'Si estás de acuerdo, avanzamos con toda la operación legal para que puedas recibir tu dinero al instante.',
      src: '/images/home/compramos_tu_propiedad.png',
    },
  ],
};

export default function Services() {
  const [selected, setSelected] = useState<'buy' | 'sell'>('buy');
  const buttonClasses =
    'py-2 px-10 transition-all duration-400 w-1/2 font-semibold whitespace-nowrap border-b-4 border-ghost-blue-400';

  return (
    <div className="relative">
      <div className="flex flex-col justify-center items-center mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h3 className="mt-2 text-3xl text-primary">¿Cómo funciona?</h3>
        <div className="mt-10 ">
          <button
            onClick={() => setSelected('buy')}
            className={`${buttonClasses} ${
              selected === 'buy' && 'text-primary !border-primary'
            }`}
          >
            Quiero comprar
          </button>
          <button
            onClick={() => setSelected('sell')}
            className={`${buttonClasses} ${
              selected === 'sell' && 'text-primary !border-primary'
            }`}
          >
            Quiero vender
          </button>
        </div>
        <section className="mt-12 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 items-start">
          {BuyAndSellFeatures[selected].map((feature, index) => (
            <div
              key={index}
              className="pb-4 flex flex-col items-center justify-center border border-secondary/50 rounded-3xl bg-white"
            >
              <Image
                priority
                src={feature.src}
                alt="Buy feat"
                width={400}
                height={100}
                className="mb-12 h-auto w-[400px] rounded-t-3xl"
              />
              <p className="text-slate-900 text-lg font-semibold leading-relaxed mb-6 ">
                {feature.name}
              </p>
              <p className="max-w-[17rem] h-[120px] text-sm opacity-70 text-slate-900 ">
                {feature.description}
              </p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
